<template>
<div>
  <el-card>
    <el-row>
      <el-col :span="8">
        <el-input placeholder="请输入单位名称搜索" v-model="unitname" clearable>
          <el-button slot="append" icon="el-icon-search" @click="onSearch">搜索</el-button>
        </el-input>
      </el-col>
      <el-col :span="8">
        <div></div>
      </el-col>
      <el-col :span="6" style="float: right;text-align: right">
        <el-button type="success" icon="el-icon-circle-plus-outline" round @click="onAddUnit">新增</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="Unitname" label="单位名称"></el-table-column>
          <el-table-column prop="Unitlevel" label="级别"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="light" content="编辑" placement="top" :hide-after="hideafter">
                <el-button
                    type="primary"
                    icon="el-icon-edit-outline"
                    circle
                    @click="handleEdit(scope.$index, scope.row)">
                </el-button>
              </el-tooltip>
              <el-tooltip effect="light" content="删除" placement="top" :hide-after="hideafter">
                <el-button
                    style="margin-left: 30px"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="handleDelete(scope.$index, scope.row)">
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-card>

  <!--新增 弹出框操作begin-->
  <el-dialog
      title="新增部门"
      :visible.sync="dialogVisible"
      width="40%"
      @close="oncloseadd">
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
      <el-form-item label="单位名称" prop="unitname">
        <el-input v-model="addForm.unitname"></el-input>
      </el-form-item>
      <el-form-item label="级别" prop="unitlevel">
        <el-input-number v-model="addForm.unitlevel" @change="handleChange" :min="1" :max="10" label="级别">
        </el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onsave">确 定</el-button>
  </span>
  </el-dialog>
  <!--新增 弹出框操作end-->

  <!--编辑 弹出框操作begin-->
  <el-dialog
      title="修改用户"
      :visible.sync="editdialogVisible"
      width="40%"
      @close="oncloseedit">
    <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
      <el-form-item label="部门名称" prop="unitname">
        <el-input v-model="editForm.unitname"></el-input>
      </el-form-item>
      <el-form-item label="级别" prop="unitlevel">
        <el-input-number v-model="editForm.unitlevel" @change="handleeditChange" :min="1" :max="10" label="级别">
        </el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="editdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onedit">确 定</el-button>
  </span>
  </el-dialog>
  <!--编辑 弹出框操作end-->
</div>
</template>

<script>
import {
  DeleteDept, DeleteUnit,
  GetAllDeptList,
  GetSearchDeptList,
  GetSearchUnitList,
  GetUnitList,
  postAddDept,
  postAddUnit, postEditDept, postEditUnit
} from "@/network/user";

export default {
  name: "Unit",
  data(){
    return{
      unitname:'',
      tableData:[],
      hideafter:3000,
      //新增
      addForm:{
        unitname:'',
        unitlevel:'',
        superior:''//上级
      },
      //新增用户字段验证规则
      addFormRules:{
        unitname: [
          { required: true, message: '请输入单位名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字', trigger: 'blur' }
        ],
        unitlevel: [
          { required: true, message: '请输入级别', trigger: 'blur' }
        ]
      },
      //是否显示新增对话框
      dialogVisible:false,
      //编辑部门
      editForm:{
        unitname:'',
        unitlevel:'',
        unitid:''
      },
      //编辑用户字段验证规则
      editFormRules:{
        unitname: [
          { required: true, message: '请输入单位名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字', trigger: 'blur' }
        ],
        unitlevel: [
          { required: true, message: '请输入级别', trigger: 'blur' }
        ]
      },
      //是否显示编辑对话框
      editdialogVisible:false,
    }
  },
  created() {
    this.initUint()
  },
  methods:{
    initUint(){
      GetUnitList(this.$store.state.token).then(res => {
        console.log(res);
        this.tableData =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    onSearch(){
      if (this.unitname !== ''){
        GetSearchUnitList(this.$store.state.token,this.unitname).then(res => {
          this.tableData =res.data
        }).catch(err => {
          console.log(err);
        })
      }else{
        this.initUint()
      }
    },
    onAddUnit(){
      this.dialogVisible=true
    },
    handleEdit(index,row){
      this.editdialogVisible =true
      this.editForm.unitid =row.Id
      this.editForm.unitname=row.Unitname
      this.editForm.unitlevel =row.Unitlevel
    },
    handleDelete(index,row){
      this.$confirm('此操作将删除该单位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteUnit(this.$store.state.token,row.Id).then(res => {
          console.log(res)
          if (res.data===true) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message({
              type: 'warning',
              message: '删除失败!'
            });
          }
          //刷新数据
          if (this.unitname !== '')
          {
            GetSearchUnitList(this.$store.state.token,this.unitname).then(res => {
              this.tableData =res.data
            }).catch(err => {
              console.log(err);
            })
          }else
          {
            this.initUint()
          }
        }).catch(err => {
          console.log(err);
        })
      }).catch(() => {

      });
    },
    //计数器
    handleChange(value){
      this.addForm.unitlevel=value
    },
    //关闭新增对话框，清除验证
    oncloseadd(){
      this.$refs.addFormRef.resetFields()
    },
    //新增按钮，点击保存
    onsave(){
      this.$refs.addFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('unitname', this.addForm.unitname);
        frmDate.append('unitlevel', this.addForm.unitlevel);
        postAddUnit(this.$store.state.token,frmDate).then(res => {
          if (res.isadd==false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.dialogVisible=false
            if (this.unitname !== '')
            {
              GetSearchUnitList(this.$store.state.token,this.unitname).then(res => {
                this.tableData =res.data
              }).catch(err => {
                console.log(err);
              })
            }else
            {
              this.initUint()
            }
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
    //关闭编辑对话框，清除验证
    oncloseedit(){
      this.$refs.editFormRef.resetFields()
    },
    handleeditChange(value){
      this.editForm.deptlevel=value
    },
    onedit(){
      this.$refs.editFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('unitid', this.editForm.unitid);
        frmDate.append('unitname', this.editForm.unitname);
        frmDate.append('unitlevel', this.editForm.unitlevel);
        postEditUnit(this.$store.state.token,frmDate).then(res => {
          if (res.isadd==false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.editdialogVisible=false
            //刷新数据
            if (this.unitname !== '')
            {
              GetSearchUnitList(this.$store.state.token,this.unitname).then(res => {
                this.tableData =res.data
              }).catch(err => {
                console.log(err);
              })
            }else
            {
              this.initUint()
            }
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
  }
}
</script>

<style scoped>
.el-table{
  margin: 20px 0px;
}
.el-select{
  width: 100%;
}
</style>